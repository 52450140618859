/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { updateProfileData } from '../../../../actions/profileActions';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';
import TextInput from '../../../Inputs/TextInput';
import Dropdown from '../../../Inputs/Dropdown';
import MaskedInput from '../../../Inputs/MaskedInput';
import CustomTextInput from '../../../Inputs/CustomTextInput';
// import DateDropdown from '../../../Inputs/DateDropdown';
import TelInput from '../../../Inputs/TelInput';
import CellPhoneInput from '../../../Inputs/CellPhoneInput';
import Select from 'react-select';
import countries from '../../../../../public/data/countries';
import States from './data/states';
import Provinces from './data/provinces';
import reactSelectStyles from '../../../../../public/css/react-select.css';
import reactTelSelectStyles from '../../../../../public/css/react-tel-select.css';
import moment from 'moment-timezone';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import Button from '../../../../sites/components/library/button';
import Headline from '../../../../sites/components/library/headline';
import reactDatesStyles from '../../../../../public/css/authorized-program-dates.css';
import s from './Profile.scss';
import eye from '../../../../shared/images/eye@3x.png';
import hiddenEye from '../../../../shared/images/hiddenEye.svg';
import lock from '../../../../shared/images/lock.png';
import Ability from '../../../../hoc/Ability';
import _clone from 'lodash/clone';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
import _range from 'lodash/range';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    const profile = this.props.profile[0];

    this.state = {
      personalInfoExpanded: false,
      academicInfoExpanded: false,
      contactInfoExpanded: false,
      emergencyContactInfoExpanded: false,
      passportVisaExpanded: false,
      customFieldsExpanded: false,
      passportMasked: true,
      inputType: '',
      dobType: true,
      passportStatus: '',
      allowSubmission: true,
      date: null,
      focused: null,
      pedFocused: null,
      preferred_first_name: profile.info.preferred_first_name,
      middle_name: profile.profile.middle_name || '',
      preferred_last_name: profile.info.preferred_last_name || '',
      first_name: profile.profile.first_name || '',
      last_name: profile.profile.last_name || '',
      status: profile.info.status || '',
      title: profile.info.title || '',
      student_id: profile.info.student_id || '',
      pronoun: profile.info.pronoun || '',
      gender: profile.info.gender || '',
      gender_identity_id: profile.info.gender_identity_id || '',
      gender_identity_additional_text: profile.info.gender_identity_additional_text || '',
      showAdditionalText: profile.info.gender_identity_id === 10,
      dob: profile.info.dob || '',
      school_name: profile.info.school_name || '',
      places_of_study: profile.info.places_of_study[0] || '',
      year_in_school: profile.info.year_in_school || '',
      ethnicity: profile.info.ethnicity || '',
      race: profile.info.race || '',
      disability: profile.info.disability || '',
      residency: profile.info.residency || '',
      veteran_status: profile.info.veteran_status || '',
      traveler_type: profile.info.traveler_type || '',
      advisor: profile.info.advisor || '',
      grad_year: profile.info.grad_year || '',
      honors_status: profile.info.honors_status || '',
      first_generation_status: profile.info.first_generation_status || '',
      cumulative_gpa: profile.info.cumulative_gpa || '',
      major_gpa: profile.info.major_gpa || '',
      primary_major: profile.info.primary_major || '',
      secondary_major: profile.info.secondary_major || '',
      primary_minor: profile.info.primary_minor || '',
      secondary_minor: profile.info.secondary_minor || '',
      third_minor: profile.info.third_minor || '',
      hours_earned: profile.info.hours_earned || '',
      judicial_status: profile.info.judicial_status || '',
      traveler_email: profile.traveler_email,
      current_address_country: profile.info.current_address_country || '',
      current_address_state: profile.info.current_address_state || '',
      permanent_address_country: profile.info.permanent_address_country || '',
      country_code: profile.info.country_code || '',
      phone_number: profile.info.phone_number || '',
      on_site_phone_number: profile.info.on_site_phone_number || '',
      safe_check_phone_number: profile.info.safe_check_phone_number || '',
      personal_email: profile.info.personal_email || '',
      current_address_line_1: profile.info.current_address_line_1 || '',
      current_address_line_2: profile.info.current_address_line_2 || '',
      current_address_city: profile.info.current_address_city || '',
      current_address_zip_code: profile.info.current_address_zip_code || '',
      permanent_address_line_1: profile.info.permanent_address_line_1 || '',
      permanent_address_line_2: profile.info.permanent_address_line_2 || '',
      permanent_address_city: profile.info.permanent_address_city || '',
      permanent_address_state: profile.info.permanent_address_state || '',
      permanent_address_zip_code: profile.info.permanent_address_zip_code || '',
      emergency_contact_first_name: profile.info.emergency_contact_first_name || '',
      emergency_contact_last_name: profile.info.emergency_contact_last_name || '',
      emergency_contact_relationship: profile.info.emergency_contact_relationship || '',
      emergency_contact_email: profile.info.emergency_contact_email || '',
      emergency_contact_country_code: profile.info.emergency_contact_country_code || '',
      emergency_contact_phone_number: profile.info.emergency_contact_phone_number || '',
      emergency_contact_address_line_1: profile.info.emergency_contact_address_line_1 || '',
      emergency_contact_address_line_2: profile.info.emergency_contact_address_line_2 || '',
      emergency_contact_address_city: profile.info.emergency_contact_address_city || '',
      emergency_contact_address_state: profile.info.emergency_contact_address_state || '',
      emergency_contact_address_zip_code: profile.info.emergency_contact_address_zip_code || '',
      emergency_contact_address_country: profile.info.emergency_contact_address_country || '',
      secondary_emergency_contact_first_name: profile.info.secondary_emergency_contact_first_name || '',
      secondary_emergency_contact_last_name: profile.info.secondary_emergency_contact_last_name || '',
      secondary_emergency_contact_relationship: profile.info.secondary_emergency_contact_relationship || '',
      secondary_emergency_contact_email: profile.info.secondary_emergency_contact_email || '',
      secondary_emergency_contact_country_code: profile.info.secondary_emergency_contact_country_code || '',
      secondary_emergency_contact_phone_number: profile.info.secondary_emergency_contact_phone_number || '',
      secondary_emergency_contact_address_line_1: profile.info.secondary_emergency_contact_address_line_1 || '',
      secondary_emergency_contact_address_line_2: profile.info.secondary_emergency_contact_address_line_2 || '',
      secondary_emergency_contact_address_city: profile.info.secondary_emergency_contact_address_city || '',
      secondary_emergency_contact_address_state: profile.info.secondary_emergency_contact_address_state || '',
      secondary_emergency_contact_address_zip_code: profile.info.secondary_emergency_contact_address_zip_code || '',
      secondary_emergency_contact_address_country: profile.info.secondary_emergency_contact_address_country || '',
      place_of_birth: profile.info.place_of_birth || '',
      countries_of_citizenship: profile.info.countries_of_citizenship || [],
      passport_status: profile.passport_status ? profile.passport_status.status : '',
      passport_number: profile.info.passport_number || '',
      passport_issuing_agency: profile.info.passport_issuing_agency || '',
      pedDay: moment(profile.info.passport_expiration_date, 'YYYY/MM/DD').date(),
      pedMonth: 1 + moment(profile.info.passport_expiration_date, 'YYYY/MM/DD').month(),
      pedYear: moment(profile.info.passport_expiration_date, 'YYYY/MM/DD').year(),
      passport_expiration_date: profile.info.passport_expiration_date || '',
      pedType: true,
      visa_description: profile.info.visa_description || '',
      custom_field_semicolon_errors: [],
    };

    profile.custom_fields.forEach(field => {
      this.state[
        `custom_field_${field.id ||
          field?.title
            .toLowerCase()
            .trim()
            .replaceAll(' ', '_')}`
      ] = field.answer;
    });

    this.months = Array.from(new Array(12), (val, index) => index + 1);
    this.days = Array.from(new Array(31), (val, index) => index + 1);
    this.years = Array.from(
      new Array(new Date().getFullYear() - 1900),
      (val, index) => new Date().getFullYear() - index,
    );
    this.expiryYears = Array.from(new Array(50), (val, index) => index + 2000);
    this.showHide = this.showHide.bind(this);
    this.toggleDob = this.toggleDob.bind(this);
    this.togglePassportExpirationDate = this.togglePassportExpirationDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.setMultipleValues = this.setMultipleValues.bind(this);
    this.openBlock = this.openBlock.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.profile[0].info.passport_number) {
      this.setState({
        inputType: 'password',
      });
    }
    if (this.state.dob === 'NaN-NaN-NaN' || [this.state.dob].includes('NaN')) {
      this.setState({
        dob: null,
      });
    }
    if (
      this.state.passport_expiration_date === 'NaN-NaN-NaN' ||
      [this.state.passport_expiration_date].includes('NaN')
    ) {
      this.setState({
        passport_expiration_date: null,
      });
    }
  }

  openBlock(evt) {
    const block = evt.target.dataset.blockType;
    block === 'customFieldsExpanded' && !this.state.customFieldsExpanded
      ? this.setState({
          [block]: !this.state[block],
        })
      : block === 'customFieldsExpanded' && this.state.customFieldsExpanded
      ? this.setState({
          [block]: !this.state[block],
        })
      : this.setState({ [block]: !this.state[block] });
  }

  setMultipleValues = values => {
    if (values === undefined || values === null) {
      this.setState({ countries_of_citizenship: [''] });
    } else {
      let newValues = values.map(obj => {
        return obj.value;
      });

      this.setState({ countries_of_citizenship: newValues });
    }
  };

  validateEmail(event) {
    const emailInput = event.target.value;
    const isValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      emailInput,
    );

    if (isValid || emailInput === '') {
      event.target.classList.remove(s.hasDanger);
      this.setState({ allowSubmission: true });
    } else {
      event.target.classList.add(s.hasDanger);
      this.setState({ allowSubmission: false });
    }
  }

  validateCustomField(event) {
    const customFieldInput = event.target.value;

    let semicolon_errors = [];

    // for (let key in this.state) {
    //   if (key.includes('custom_field_')) {
    //     if (this.state?.[key]?.includes(';')) {
    //       if (!semicolon_errors.includes(key)) {
    //         semicolon_errors.push(key);
    //       }
    //     }
    //   }
    // }

    this.setState({
      custom_field_semicolon_errors: semicolon_errors,
    });

    if (semicolon_errors.length > 0) {
      this.setState({ allowSubmission: false });
    } else {
      this.setState({ allowSubmission: true });
    }
  }

  requireAdditionalText(event) {
    const isValid = event.target.value.length > 0;

    if (isValid) {
      event.target.classList.remove(s.hasDanger);

      this.setState({ allowSubmission: true });
    } else {
      event.target.classList.add(s.hasDanger);

      this.setState({ allowSubmission: false });
    }
  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      inputType: this.state.inputType === 'text' ? 'password' : 'text',
    });
  }

  // THE SAME AS SHOW PASSPORT
  toggleDob(e) {
    this.setState({
      dobType: !this.state.dobType,
    });
  }

  togglePassportExpirationDate(e) {
    this.setState({
      pedType: !this.state.pedType,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let travelerInfo = _clone(this.state);
    let passport = {};
    let profile = {};
    let custom_fields = {};

    const customFields = this.props.profile[0].custom_fields.map(field => {
      return {
        value: `custom_field_${field.id}`,
        integration_field: field.integration_field,
      };
    });

    const custom_field_keys = customFields.filter(field => !field.integration_field).map(field => field.value);

    custom_field_keys.map(key => (custom_fields[key.replace('custom_field_', '')] = travelerInfo[key]));
    custom_field_keys.map(key => delete travelerInfo[key]);

    passport.status = travelerInfo.passport_status;
    profile.first_name = travelerInfo.first_name;
    profile.middle_name = travelerInfo.middle_name;
    profile.last_name = travelerInfo.last_name;

    delete travelerInfo['first_name'];
    delete travelerInfo['last_name'];
    delete travelerInfo['email'];
    delete travelerInfo['passport_status'];

    travelerInfo.places_of_study = [travelerInfo.places_of_study].flat();
    travelerInfo.countries_of_citizenship = travelerInfo.countries_of_citizenship;

    if (this.state.on_site_phone_number.length === 0) {
      this.setState({
        on_site_country_code: '',
        on_site_national_format: '',
      });
    }

    this.props.updateProfileData(this.props.id, travelerInfo, passport, profile, custom_fields, this.props.role);
    this.setState({ dobType: true, pedType: true });
  }

  onInputChange(event) {
    if (event.target.name === 'passport_number') {
      this.setState({
        [event.target.name]: event.target.value,
      });
      event.target.value.length === 0 ? this.setState({ input: 'text' }) : this.setState({ input: 'password' });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  onPhoneInputChange = phone => {
    this.setState({
      on_site_phone_number: phone,
    });
  };

  handleChange = (countryCode, phonefield, telNumber, selectedCountry) => {
    const phone = telNumber.replace(selectedCountry.dialCode, '').replace(/\D/g, '');

    if (phonefield === 'phone_number' && countryCode === 'country_code') {
      this.setState({
        phone_number: phone,
        country_code: selectedCountry.dialCode,
      });
    } else if (phonefield === 'emergency_contact_phone_number' && countryCode === 'emergency_contact_country_code') {
      this.setState({
        emergency_contact_phone_number: phone,
        emergency_contact_country_code: selectedCountry.dialCode,
      });
    } else if (
      phonefield === 'secondary_emergency_contact_phone_number' &&
      countryCode === 'secondary_emergency_contact_country_code'
    ) {
      this.setState({
        secondary_emergency_contact_phone_number: phone,
        secondary_emergency_contact_country_code: selectedCountry.dialCode,
      });
    }
  };

  onSelectChange(name, value) {
    this.setState({ [name]: value });

    if (name === 'gender_identity_id' && parseInt(value) === 10) {
      this.setState({ showAdditionalText: true });
    } else if (name === 'gender_identity_id') {
      this.setState({ ['gender_identity_additional_text']: '', showAdditionalText: false });
    }
  }

  render() {
    if (this.props.loading == true) {
      return (
        <div>
          <h1>Loading...</h1>
        </div>
      );
    } else if (this.props.profile == null) {
      return <div>error</div>;
    } else {
      const profile = this.props.profile[0];

      const readonly = this.props.profile[0].readonly;

      const { permissions, currentUser } = this.props;

      let disableAll = true;

      let disableStaus = true;

      let hideDob = this.state.dob && this.state.dobType ? `${s.toggleDisc}` : '';
      let hidePassportExpiration = this.state.passport_expiration_date && this.state.pedType ? `${s.toggleDisc}` : '';

      if (currentUser && currentUser['is_traveler?']) {
        disableAll = false;
        disableStaus = false;
      }
      if (
        permissions['traveler_information_settings'] &&
        permissions['traveler_information_settings'].includes('edit_traveler_profile')
      ) {
        disableAll = false;
      }

      if (
        permissions['traveler_information_settings'] &&
        permissions['traveler_information_settings'].includes('inactivate_traveler')
      ) {
        disableStaus = false;
      }

      /* START CUSTOM FIELDS SECTION */
      profile.custom_fields ? null : (profile.custom_fields = []);

      const customFields = profile.custom_fields.map(field => (
        <>
          <CustomTextInput
            name={`custom_field_${field.id ||
              field?.title
                .toLowerCase()
                .trim()
                .replaceAll(' ', '_')}`}
            value={
              this.state[
                `custom_field_${field.id ||
                  field?.title
                    .toLowerCase()
                    .trim()
                    .replaceAll(' ', '_')}`
              ]
            }
            onBlur={this.validateCustomField.bind(this)}
            onChange={this.onInputChange}
            label={field.title}
            disabled={field.integration_field}
            message={
              this.state?.custom_field_semicolon_errors?.includes?.(
                `custom_field_${field.id ||
                  field?.title
                    .toLowerCase()
                    .trim()
                    .replaceAll(' ', '_')}`,
              )
                ? 'Invalid character “;”, please remove and try again.'
                : ''
            }
          />

          {this.state?.custom_field_semicolon_errors?.includes?.(
            `custom_field_${field.id ||
              field?.title
                .toLowerCase()
                .trim()
                .replaceAll(' ', '_')}`,
          ) && (
            <>
              <br />
              <span>Having semicolon error</span>
            </>
          )}
        </>
      ));
      /* END CUSTOM FIELDS SECTION */

      let monthsOptions = [{ value: '', label: '' }];

      let daysOptions = [{ value: '', label: '' }];

      let yearsOptions = [{ value: '', label: '' }];

      const years = _range(2010, 2051);
      let expiryYearsOptions = [];
      years.forEach(function(val) {
        expiryYearsOptions.push({ value: val, label: val });
      });

      let monthnames = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
      };

      this.months.map(month => {
        monthsOptions.push({ value: month, label: monthnames[month] });
      });

      this.days.map(day => {
        daysOptions.push({ value: day, label: day });
      });

      const customStyles = {
        menu: (provided, state) => ({
          ...provided,
          zIndex: '12',
        }),

        control: provided => ({
          ...provided,
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          padding: '5px',
          height: '88px',
        }),

        placeholder: () => ({
          fontFamily: 'AvenirNextRegular, sans-serif',
          color: '#043544',
          position: 'relative',
          padding: '20px 0 10px 20px',
          width: '100%',
          background: 'transparent',
          border: 'none',
          fontSize: '1.5rem',
          transition: '0.3s ease background',
          lineHeight: '2rem',
        }),
        input: () => ({
          caretColor: 'transparent',
        }),

        indicatorsContainer: () => ({
          height: '88px',
        }),

        valueContainer: () => ({
          height: '88px',
          display: 'contents',
        }),

        multiValue: () => ({
          display: 'flex',
          marginTop: '10px',
          fontFamily: 'AvenirNextRegular, sans-serif',
          color: '#043544',
          fontSize: '1.5rem',
        }),
      };

      const yearRange = () => {
        const years = [];
        const dateStart = moment();
        const dateEnd = moment().subtract(100, 'y');

        while (dateEnd.diff(dateStart, 'years') <= 0) {
          years.push(dateStart.format('YYYY'));
          dateStart.subtract(1, 'year');
        }
        return years;
      };

      const passportExpirationYearRange = () => {
        const years = [];
        const dateStart = moment().add(20, 'y');
        const dateEnd = moment().subtract(20, 'y');

        while (dateEnd.diff(dateStart, 'years') <= 0) {
          years.push(dateStart.format('YYYY'));
          dateStart.subtract(1, 'year');
        }
        return years;
      };

      const allGenderIdentityOptions =
        this.props.allGenderIdentities?.length > 0 &&
        this.props.allGenderIdentities.map(genderIdentity => {
          return { value: genderIdentity.id, label: genderIdentity.attributes.name };
        });
      const currentGenderIdentityOption = _find(this.props.allGenderIdentities, genderIdentity => {
        return genderIdentity.id == this.state.gender_identity_id;
      });
      const graduationYearOptions = () => {
        let currentYear = moment().year();
        let currentYearSelection = parseInt(this.state.grad_year);
        let yearOptions = [{ value: '', label: '' }];
        let yearRange = _range(currentYear - 20, currentYear + 21);
        let currentYearSelectionIncluded = _includes(yearRange, currentYearSelection);

        if (currentYearSelection && !currentYearSelectionIncluded && currentYearSelection < yearRange[0]) {
          yearRange.unshift(currentYearSelection);
        } else if (currentYearSelection && !currentYearSelectionIncluded) {
          yearRange.push(currentYearSelection);
        }

        yearRange.forEach(year => {
          yearOptions.push({ label: year, value: year });
        });

        return yearOptions;
      };
      const nullCountry = { value: '', label: '' };

      return (
        <form className={s.wrapper} onSubmit={e => this.handleSubmit(e)}>
          <div className={s.contentHeadline}>
            <span className={s.headline}>
              <Headline as="h1" aria-label={`${this.props.customAliases.alias_traveler} Information`}>
                {this.props.customAliases.alias_traveler} Information
              </Headline>
            </span>
            <span className={s.button}>
              {currentUser['is_traveler?'] ? (
                <Button
                  display="primary"
                  kind="solid"
                  size="medium"
                  type="submit"
                  aria-label="Save Information Button"
                  disabled={!this.state.allowSubmission}
                >
                  Save
                </Button>
              ) : (
                <Ability
                  module="traveler_information_settings"
                  action="edit_traveler_profile"
                  disabled={!this.state.allowSubmission}
                >
                  <Button
                    display="primary"
                    kind="solid"
                    size="medium"
                    type="submit"
                    aria-label="Save Information Button"
                    disabled={!this.state.allowSubmission}
                  >
                    Save
                  </Button>
                </Ability>
              )}
            </span>
          </div>
          <div className={s.accordion} data-block-type="personalInfoExpanded" onClick={this.openBlock}>
            <div className={s.sectionHeader}>
              <Headline
                as="h2"
                font="avenirMedium"
                aria-label="Personal Information"
                data-block-type="personalInfoExpanded"
              >
                Personal Information
              </Headline>
            </div>
            <div className={s.iconWrapper}>
              <div
                data-block-type="personalInfoExpanded"
                className={this.state.personalInfoExpanded ? `${s.icon} ${s.iconMinus}` : `${s.icon} ${s.iconPlus}`}
              />
            </div>
          </div>
          <div className={this.state.personalInfoExpanded ? 'form form--default' : `${s.hidden}`}>
            <div className={`title ${s.inputWrapper}`}>
              <label className={`${s.titleLabel}`}>Title</label>
              <Dropdown
                name="title"
                id="title"
                placeholder="Title"
                value={this.state.title}
                onChange={this.onSelectChange}
                options={[
                  { value: null, label: '' },
                  { value: 'Mr.', label: 'Mr.' },
                  { value: 'Ms.', label: 'Ms.' },
                  { value: 'Mrs.', label: 'Mrs.' },
                  { value: 'Mx.', label: 'Mx.' },
                  { value: 'Dr.', label: 'Dr.' },
                ]}
                disabled={readonly.includes('title') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="preferred_first_name"
                id="preferred_first_name"
                label="Preferred First Name"
                type="text"
                value={this.state.preferred_first_name}
                onChange={this.onInputChange}
                disabled={readonly.includes('preferred_first_name') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="preferred_last_name"
                id="preferred_last_name"
                label="Preferred Last Name"
                value={this.state.preferred_last_name}
                onChange={this.onInputChange}
                disabled={readonly.includes('preferred_last_name') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="first_name"
                id="first_name"
                value={this.state.first_name}
                onChange={this.onInputChange}
                label="First Name"
                disabled={readonly.includes('first_name') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="middle_name"
                id="middle_name"
                value={this.state.middle_name}
                onChange={this.onInputChange}
                label="Middle Name"
                disabled={readonly.includes('middle_name') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="last_name"
                id="last_name"
                value={this.state.last_name}
                onChange={this.onInputChange}
                label="Last Name"
                disabled={readonly.includes('last_name') || disableAll}
              />
            </div>
            {this.props.role != 'traveler' ? (
              <div className={`status ${s.inputWrapper}`}>
                <label className={`${s.statusLabel}`}>Status</label>
                <Dropdown
                  name="status"
                  id="status"
                  placeholder="Status"
                  value={this.state.status}
                  onChange={this.onSelectChange}
                  options={[
                    { value: null, label: '' },
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                    { value: 'new_traveler', label: 'New' },
                  ]}
                  disabled={readonly.includes('status') || disableAll || disableStaus}
                />
              </div>
            ) : null}
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="student_id"
                id="student_id"
                value={this.state.student_id}
                onChange={this.onInputChange}
                label="Student ID"
                disabled={readonly.includes('student_id') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="pronoun"
                id="pronoun"
                value={this.state.pronoun}
                onChange={this.onInputChange}
                label="Pronoun"
                disabled={readonly.includes('pronoun') || disableAll}
              />
            </div>
            <div className={`gender ${s.inputWrapper}`}>
              <label className={`${s.genderLabel}`}>Sex Assigned at Birth</label>
              <Dropdown
                name="gender"
                id="gender"
                placeholder="Sex Assigned at Birth"
                value={this.state.gender}
                onChange={this.onSelectChange}
                options={[
                  { value: null, label: '' },
                  { value: 'male', label: 'Male' },
                  { value: 'female', label: 'Female' },
                  { value: 'other_sex', label: 'Other' },
                ]}
                disabled={readonly.includes('gender') || disableAll}
              />
            </div>
            <div className={`gender_identity_id ${s.inputWrapper}`}>
              <label className={`${s.genderLabel}`}>Gender Identity</label>
              <Dropdown
                name="gender_identity_id"
                id="gender_identity_id"
                placeholder="Gender Identity"
                value={
                  currentGenderIdentityOption && currentGenderIdentityOption.attributes
                    ? currentGenderIdentityOption.attributes.name
                    : null
                }
                onChange={this.onSelectChange}
                options={allGenderIdentityOptions}
                disabled={readonly.includes('gender_identity_id') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              {this.state.showAdditionalText ? (
                <TextInput
                  name="gender_identity_additional_text"
                  id="gender_identity_additional_text"
                  value={this.state.gender_identity_additional_text}
                  onChange={this.onInputChange}
                  label="Gender Identity Additional Text"
                  disabled={readonly.includes('gender_identity_additional_text') || disableAll}
                  onBlur={this.requireAdditionalText.bind(this)}
                />
              ) : null}
            </div>
            <div className={`dob ${s.inputWrapper}`}>
              <span className={s.password_show} onClick={this.toggleDob}>
                {this.state.dobType ? (
                  <img className={s.iconSize} src={eye} />
                ) : (
                  <img className={s.iconSize} src={hiddenEye} />
                )}
              </span>
              <label className={`${s.dobLabel}`}>Date of Birth</label>
              <div className={`${hideDob}`}>
                {hideDob ? (
                  <input
                    type="password"
                    value={'************'}
                    readOnly
                    style={{ marginBottom: 10 }}
                    onClick={() => this.setState({ dobType: false })}
                  />
                ) : (
                  <SingleDatePicker
                    id="dob"
                    // name="dob"
                    displayFormat="MMM DD, YYYY"
                    dayAriaLabelFormat="MMM DD, YYYY"
                    readOnly
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                    renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                      <div className={s.dateSelectContainer}>
                        <div>
                          <select
                            value={month.month()}
                            onChange={e => {
                              onMonthSelect(month, e.target.value);
                            }}
                          >
                            {moment.months().map((label, value) => (
                              <option value={value}>{label}</option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <select
                            value={month.year()}
                            onChange={e => {
                              onYearSelect(month, e.target.value);
                            }}
                          >
                            {yearRange().map(range => (
                              <option value={range}>{range}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    date={!!this.state.dob && moment(this.state.dob)}
                    onDateChange={date => {
                      this.setState({ dob: date });
                    }}
                    focused={this.state.focused}
                    onFocusChange={({ focused }) => {
                      this.setState({ focused });
                      focused ? this.setState({ dobType: false }) : null;
                    }}
                    disabled={readonly.includes('dob') || disableAll}
                  />
                )}
              </div>
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="school_name"
                id="school_name"
                value={this.state.school_name}
                onChange={this.onInputChange}
                label="Home Campus"
                disabled={readonly.includes('school_name') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="places_of_study"
                id="places_of_study"
                value={this.state.places_of_study}
                onChange={this.onInputChange}
                label="College or Faculty"
                disabled={readonly.includes('places_of_study') || disableAll}
              />
            </div>
            <div className={`schoolYear ${s.inputWrapper}`}>
              <label className={`${s.schoolYear}`}>Year in School</label>
              <Dropdown
                name="year_in_school"
                id="year_in_school"
                placeholder="Year in School"
                value={this.state.year_in_school}
                onChange={this.onSelectChange}
                options={[
                  { value: null, label: '' },
                  { value: 'Freshman', label: 'Freshman' },
                  { value: 'Sophomore', label: 'Sophomore' },
                  { value: 'Junior', label: 'Junior' },
                  { value: 'Senior', label: 'Senior' },
                  { value: 'Graduate Student', label: 'Graduate Student' },
                  {
                    value: 'Non-degree Seeking',
                    label: 'Non-degree Seeking',
                  },
                ]}
                disabled={readonly.includes('year_in_school') || disableAll}
              />
            </div>
            <div className={`ethnicity ${s.inputWrapper}`}>
              <label className={`${s.ethnicityLabel}`}>Ethnicity</label>
              <Dropdown
                name="ethnicity"
                id="ethnicity"
                placeholder="Ethnicity"
                value={this.state.ethnicity}
                onChange={this.onSelectChange}
                options={[
                  { value: null, label: '' },
                  {
                    value: 'Hispanic/Latino of any race',
                    label: 'Hispanic/Latino of any race',
                  },
                  {
                    value: 'Not Hispanic or Latino',
                    label: 'Not Hispanic or Latino',
                  },
                ]}
                disabled={readonly.includes('ethnicity') || disableAll}
              />
            </div>
            <div className={`race ${s.inputWrapper}`}>
              <label className={`${s.raceLabel}`}>Race</label>
              <Dropdown
                name="race"
                id="race"
                placeholder="Race"
                value={this.state.race}
                onChange={this.onSelectChange}
                options={[
                  { value: null, label: '' },
                  {
                    value: 'American Indian or Alaska Native',
                    label: 'American Indian or Alaska Native',
                  },
                  { value: 'Asian', label: 'Asian' },
                  {
                    value: 'Black or African American',
                    label: 'Black or African American',
                  },
                  {
                    value: 'Native Hawaiian or Other Pacific Islander',
                    label: 'Native Hawaiian or Other Pacific Islander',
                  },
                  { value: 'White', label: 'White' },
                  { value: 'Two or more', label: 'Two or more' },
                  { value: 'Do not know', label: 'Do not know' },
                ]}
                disabled={readonly.includes('race') || disableAll}
              />
            </div>
            <div className={`disability ${s.inputWrapper}`}>
              <TextInput
                name="disability"
                id="disability"
                value={this.state.disability}
                onChange={this.onInputChange}
                label="Disability"
                disabled={readonly.includes('disability') || disableAll}
              />
            </div>
            <div className={`residencyStatus ${s.inputWrapper}`}>
              <label className={`${s.residencyStatusLabel}`}>Residency Status</label>
              <Dropdown
                name="residency"
                id="residency"
                placeholder="Residency Status"
                value={this.state.residency}
                onChange={this.onSelectChange}
                options={[
                  { value: null, label: '' },
                  { value: 'citizen', label: 'Citizen' },
                  { value: 'in_state', label: 'In State' },
                  {
                    value: 'international',
                    label: 'International Student/Visitor',
                  },
                  { value: 'national', label: 'National' },
                  { value: 'out_of_state', label: 'Out of State' },
                  { value: 'permanent_resident', label: 'Permanent Resident' },
                  { value: 'refugee', label: 'Refugee/Stateless' },
                  { value: 'other_residency', label: 'Other' },
                ]}
                disabled={readonly.includes('residency') || disableAll}
              />
            </div>
            <div className={`veteran ${s.inputWrapper}`}>
              <label className={`${s.veteranLabel}`}>Veteran</label>
              <Dropdown
                id="veteran_status"
                name="veteran_status"
                placeholder="Veteran"
                value={this.state.veteran_status}
                onChange={this.onSelectChange}
                options={[
                  { value: null, label: '' },
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ]}
                disabled={readonly.includes('veteran_status') || disableAll}
              />
            </div>
          </div>
          <div className={s.accordion} data-block-type="academicInfoExpanded" onClick={this.openBlock}>
            <div className={s.sectionHeader}>
              <Headline
                as="h2"
                font="avenirMedium"
                aria-label="Academic Information"
                data-block-type="academicInfoExpanded"
              >
                Academic Information
              </Headline>
            </div>
            <div className={s.iconWrapper}>
              <div
                data-block-type="academicInfoExpanded"
                className={this.state.academicInfoExpanded ? `${s.icon} ${s.iconMinus}` : `${s.icon} ${s.iconPlus}`}
              />
            </div>
          </div>
          <div className={this.state.academicInfoExpanded ? 'form form--default' : `${s.hidden}`}>
            <div className={`travelerType ${s.inputWrapper}`}>
              <label className={`${s.travelerTypeLabel}`}>{this.props.customAliases.alias_traveler} Type</label>
              <Dropdown
                name="traveler_type"
                id="traveler_type"
                placeholder="Traveler Type"
                value={this.state.traveler_type}
                onChange={this.onSelectChange}
                options={[
                  { value: null, label: '' },
                  { value: 'high_school', label: 'High School/Secondary' },
                  { value: 'undergraduate', label: 'Undergraduate' },
                  { value: 'graduate', label: 'Graduate' },
                  { value: 'post_graduate', label: 'Postgraduate' },
                  { value: 'faculty', label: 'Faculty' },
                  { value: 'staff', label: 'Staff' },
                  { value: 'guest', label: 'Guest' },
                  { value: 'other', label: 'Other' },
                ]}
                disabled={readonly.includes('traveler_type') || disableAll}
              />
            </div>

            <div className={`gradYear ${s.inputWrapper}`}>
              <label className={`${s.gradYearLabel}`}>Anticipated Graduation Year</label>
              <Dropdown
                name="grad_year"
                id="grad_year"
                placeholder="Anticipated Graduation Year"
                value={this.state.grad_year}
                onChange={this.onSelectChange}
                options={graduationYearOptions()}
                disabled={readonly.includes('grad_year') || disableAll}
              />
            </div>
            <div className={`advisor ${s.inputWrapper}`}>
              <TextInput
                name="advisor"
                id="advisor"
                label="Advisor"
                value={this.state.advisor}
                onChange={this.onInputChange}
                disabled={readonly.includes('advisor') || disableAll}
              />
            </div>
            <div className={`cumulative_gpa ${s.inputWrapper}`}>
              <MaskedInput
                name="cumulative_gpa"
                id="cumulative_gpa"
                label="GPA - Cumulative"
                value={this.state.cumulative_gpa}
                onChange={this.onInputChange}
                options={{
                  numericOnly: true,
                  blocks: [1, 4],
                  delimiter: '.',
                }}
                disabled={readonly.includes('cumulative_gpa') || disableAll}
              />
            </div>
            <div className={`major_gpa ${s.inputWrapper}`}>
              <MaskedInput
                name="major_gpa"
                id="major_gpa"
                label="GPA - Major"
                value={this.state.major_gpa}
                onChange={this.onInputChange}
                options={{
                  numericOnly: true,
                  blocks: [1, 4],
                  delimiter: '.',
                }}
                disabled={readonly.includes('major_gpa') || disableAll}
              />
            </div>
            <div className={`primary_major ${s.inputWrapper}`}>
              <TextInput
                name="primary_major"
                currentGenderIdentityOption="primary_major"
                value={this.state.primary_major}
                onChange={this.onInputChange}
                label="Major - Primary"
                disabled={readonly.includes('primary_major') || disableAll}
              />
            </div>
            <div className={`secondary_major ${s.inputWrapper}`}>
              <TextInput
                name="secondary_major"
                id="secondary_major"
                value={this.state.secondary_major}
                onChange={this.onInputChange}
                label="Major - Secondary"
                disabled={readonly.includes('secondary_major') || disableAll}
              />
            </div>
            <div className={`primary_major ${s.inputWrapper}`}>
              <TextInput
                name="primary_minor"
                id="primary_minor"
                value={this.state.primary_minor}
                onChange={this.onInputChange}
                label="Minor - Primary"
                disabled={readonly.includes('primary_minor') || disableAll}
              />
            </div>
            <div className={`secondary_minor ${s.inputWrapper}`}>
              <TextInput
                name="secondary_minor"
                id="secondary_minor"
                value={this.state.secondary_minor}
                onChange={this.onInputChange}
                label="Minor - Secondary"
                disabled={readonly.includes('secondary_minor') || disableAll}
              />
            </div>
            <div className={`third_minor ${s.inputWrapper}`}>
              <TextInput
                name="third_minor"
                id="third_minor"
                value={this.state.third_minor}
                onChange={this.onInputChange}
                label="Minor - Third"
                disabled={readonly.includes('third_minor') || disableAll}
              />
            </div>
            <div className={`hours_earned ${s.inputWrapper}`}>
              <MaskedInput
                name="hours_earned"
                id="hours_earned"
                value={this.state.hours_earned}
                onChange={this.onInputChange}
                label="Hours Earned"
                options={{
                  numeral: true,
                  numeralDecimalScale: 1,
                  numeralThousandsGroupStyle: 'none',
                }}
                disabled={readonly.includes('hours_earned') || disableAll}
              />
            </div>
            <div className={`honorsStatus ${s.inputWrapper}`}>
              <label className={`${s.honorsStatusLabel}`}>Honors Status</label>
              <Dropdown
                name="honors_status"
                id="honors_status"
                placeholder="Honors Status"
                value={this.state.honors_status}
                onChange={this.onSelectChange}
                options={[
                  { value: null, label: '' },
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                disabled={readonly.includes('honors_status') || disableAll}
              />
            </div>
            <div className={`firstGenerationStatus ${s.inputWrapper}`}>
              <label className={`${s.honorsStatusLabel}`}>First-Generation Status</label>
              <Dropdown
                name="first_generation_status"
                id="first_generation_status"
                placeholder="First-Generation Status"
                value={this.state.first_generation_status}
                onChange={this.onSelectChange}
                options={[
                  { value: null, label: '' },
                  {
                    label:"Yes",
                    value:"yes"
                  },
                  {
                    label:"No",
                    value:"no"
                  },
                  {
                    label:"I’m Not Certain",
                    value:"not_certain"
                  },
                  {
                    label:"Prefer Not to Answer",
                    value:"prefer_not_answer"
                  },
                ]}
                disabled={readonly.includes('first_generation_status') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="judicial_status"
                id="judicial_status"
                value={this.state.judicial_status}
                onChange={this.onInputChange}
                label="Judicial Status"
                disabled={readonly.includes('judicial_status') || disableAll}
              />
            </div>
          </div>
          <div className={s.accordion} data-block-type="contactInfoExpanded" onClick={this.openBlock}>
            <div className={s.sectionHeader}>
              <Headline
                as="h2"
                font="avenirMedium"
                aria-label="Contact Information"
                data-block-type="contactInfoExpanded"
              >
                Contact Information
              </Headline>
            </div>
            <div className={s.iconWrapper}>
              <div
                data-block-type="contactInfoExpanded"
                className={this.state.contactInfoExpanded ? `${s.icon} ${s.iconMinus}` : `${s.icon} ${s.iconPlus}`}
              />
            </div>
          </div>
          <div className={this.state.contactInfoExpanded ? 'form form--default' : `${s.hidden}`}>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="email"
                id="email"
                value={this.state.traveler_email}
                onChange={this.onInputChange}
                label="Email"
                pre_filled={true}
                disabled={profile.traveler_email ? true : readonly.includes('email') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="personal_email"
                id="personal_email"
                value={this.state.personal_email}
                onChange={this.onInputChange}
                label="Secondary Email"
                disabled={readonly.includes('personal_email') || disableAll}
                onBlur={this.validateEmail.bind(this)}
                className=""
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              {readonly.includes('phone_number') ? (
                <TextInput
                  name="phone_number"
                  id="phone_number"
                  value={this.state.phone_number}
                  label="Phone"
                  onChange={this.onInputChange}
                  disabled={true}
                />
              ) : (
                <TelInput
                  countryCodeValue={this.state.country_code || '+1'}
                  phoneValue={this.state.phone_number}
                  countryCodeField={'country_code'}
                  id="country_code"
                  name="country_code"
                  phoneField={'phone_number'}
                  label="Phone"
                  onChange={this.onInputChange}
                  handleChange={this.handleChange}
                  value={`${this.state.country_code || '+1'}${this.state.phone_number}`}
                  disabled={disableAll}
                />
              )}
            </div>
            <div className={`${s.inputWrapper}`}>
              <CellPhoneInput
                name="on_site_phone_number"
                id="on_site_phone_number"
                onChange={this.onPhoneInputChange}
                label="Mobile / On-site Phone"
                value={this.state.on_site_phone_number}
                disabled={readonly.includes('on_site_phone_number') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              {this.props.clientFeatureList.travel_plans ? (
                <TelInput
                  name="safe_check_phone_number"
                  id="safe_check_phone_number"
                  label="SafeCheck Phone"
                  value={this.state.safe_check_phone_number}
                  disabled={true}
                />
              ) : null}
            </div>
            <div className={`${s.inputWrapper}`}>
              <Headline as="h3" className={s.subHeader} aria-label="Current Address">
                Current Address
              </Headline>
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="current_address_line_1"
                id="current_address_line_1"
                value={this.state.current_address_line_1}
                onChange={this.onInputChange}
                label="Address"
                disabled={readonly.includes('current_address_line_1') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="current_address_line_2"
                id="current_address_line_2"
                value={this.state.current_address_line_2}
                onChange={this.onInputChange}
                label="Address Line 2"
                disabled={readonly.includes('current_address_line_2') || disableAll}
              />
            </div>
            <div className={`country ${s.inputWrapper}`}>
              <label className={`${s.countryLabel}`}>Country</label>
              <Dropdown
                name="current_address_country"
                id="current_address_country"
                placeholder="Country"
                value={this.state.current_address_country}
                onChange={this.onSelectChange}
                options={[{ ...nullCountry }, ...countries]}
                disabled={readonly.includes('current_address_country') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              {this.state.current_address_country === 'US' && (
                <div className={`currentState ${s.inputWrapper}`}>
                  <label className={`${s.travelerStateLabel}`}>State</label>
                  <Dropdown
                    name={this.state.current_address_country === 'US' ? 'current_address_state' : ''}
                    id="current_address_state_us"
                    value={this.state.current_address_state}
                    onChange={this.onSelectChange}
                    options={States}
                    placeholder="State/Province/Territory"
                    disabled={readonly.includes('current_address_state') || disableAll}
                  />
                </div>
              )}
            </div>
            <div className={`${s.inputWrapper}`}>
              {this.state.current_address_country === 'CA' && (
                <Dropdown
                  name={this.state.current_address_country === 'CA' ? 'current_address_state' : ''}
                  id="current_address_state_ca"
                  onChange={this.onSelectChange}
                  value={this.state.current_address_state}
                  options={Provinces}
                  placeholder="State/Province/Territory"
                  disabled={readonly.includes('current_address_state') || disableAll}
                />
              )}
            </div>
            <div className={`${s.inputWrapper}`}>
              {this.state.current_address_country !== 'US' && this.state.current_address_country !== 'CA' && (
                <TextInput
                  name={
                    this.state.current_address_country !== 'US' && this.state.current_address_country !== 'CA'
                      ? 'current_address_state'
                      : ''
                  }
                  id="current_address_state_us"
                  value={this.state.current_address_state}
                  onChange={this.onInputChange}
                  label="State/Province/Territory"
                  disabled={readonly.includes('current_address_state') || disableAll}
                />
              )}
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="current_address_city"
                id="current_address_city"
                value={this.state.current_address_city}
                onChange={this.onInputChange}
                label="City"
                disabled={readonly.includes('current_address_city') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="current_address_zip_code"
                id="current_address_zip_code"
                value={this.state.current_address_zip_code}
                onChange={this.onInputChange}
                label="Zip Code"
                // options={
                //   this.state.current_address_country === 'US'
                //     ? {
                //         numericOnly: true,
                //         delimiterLazyShow: true,
                //         blocks: [5, 5],
                //         delimiters: ['-'],
                //       }
                //     : { numericOnly: true }
                // }
                disabled={readonly.includes('current_address_zip_code') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <Headline as="h3" className={s.subHeader} aria-label="Permanent Address">
                Permanent Address
              </Headline>
              <TextInput
                name="permanent_address_line_1"
                id="permanent_address_line_1"
                value={this.state.permanent_address_line_1}
                onChange={this.onInputChange}
                label="Address"
                disabled={readonly.includes('permanent_address_line_1') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="permanent_address_line_2"
                id="permanent_address_line_2"
                value={this.state.permanent_address_line_2}
                onChange={this.onInputChange}
                label="Address Line 2"
                disabled={readonly.includes('permanent_address_line_2') || disableAll}
              />
            </div>
            <div className={`permanentCountry ${s.inputWrapper}`}>
              <label className={`${s.countryLabel}`}>Country</label>
              <Dropdown
                name="permanent_address_country"
                id="permanent_address_country"
                value={this.state.permanent_address_country}
                onChange={this.onSelectChange}
                placeholder="Country"
                options={[{ ...nullCountry }, ...countries]}
                disabled={readonly.includes('permanent_address_country') || disableAll}
              />
            </div>
            {this.state.permanent_address_country === 'US' && (
              <div className={`permanentState ${s.inputWrapper}`}>
                <label className={`${s.travelerStateLabel}`}>State</label>
                <Dropdown
                  name={this.state.permanent_address_country === 'US' ? 'permanent_address_state' : ''}
                  id="permanent_address_state_us"
                  value={this.state.permanent_address_state}
                  onChange={this.onSelectChange}
                  options={States}
                  placeholder="State/Province/Territory"
                  disabled={readonly.includes('permanent_address_state') || disableAll}
                />
              </div>
            )}
            <div className={`${s.inputWrapper}`}>
              {this.state.permanent_address_country === 'CA' && (
                <Dropdown
                  name={this.state.permanent_address_country === 'CA' ? 'permanent_address_state' : ''}
                  id="permanent_address_state_ca"
                  onChange={this.onSelectChange}
                  value={this.state.permanent_address_state}
                  options={Provinces}
                  placeholder="State/Province/Territory"
                  disabled={readonly.includes('permanent_address_state') || disableAll}
                />
              )}
            </div>
            <div className={`${s.inputWrapper}`}>
              {this.state.permanent_address_country !== 'US' && this.state.permanent_address_country !== 'CA' && (
                <TextInput
                  name={
                    this.state.permanent_address_country !== 'US' && this.state.permanent_address_country !== 'CA'
                      ? 'permanent_address_state'
                      : ''
                  }
                  id="permanent_address_state_us"
                  value={this.state.permanent_address_state}
                  onChange={this.onInputChange}
                  label="State/Province/Territory"
                  disabled={readonly.includes('permanent_address_state') || disableAll}
                />
              )}
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="permanent_address_city"
                id="permanent_address_city"
                value={this.state.permanent_address_city}
                onChange={this.onInputChange}
                label="City"
                disabled={readonly.includes('permanent_address_city') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="permanent_address_zip_code"
                id="permanent_address_zip_code"
                value={this.state.permanent_address_zip_code}
                onChange={this.onInputChange}
                label="Zip Code"
                // options={
                //   this.state.permanent_address_country === 'US'
                //     ? {
                //         numericOnly: true,
                //         delimiterLazyShow: true,
                //         blocks: [5, 5],
                //         delimiters: ['-'],
                //       }
                //     : { numericOnly: true }
                // }
                disabled={readonly.includes('permanent_address_zip_code') || disableAll}
              />
            </div>
          </div>
          <div className={s.accordion} data-block-type="emergencyContactInfoExpanded" onClick={this.openBlock}>
            <div className={s.sectionHeader}>
              <Headline
                as="h2"
                font="avenirMedium"
                aria-label="Emergency Contact"
                data-block-type="emergencyContactInfoExpanded"
              >
                Emergency Contact
              </Headline>
            </div>
            <div className={s.iconWrapper}>
              <div
                data-block-type="emergencyContactInfoExpanded"
                className={
                  this.state.emergencyContactInfoExpanded ? `${s.icon} ${s.iconMinus}` : `${s.icon} ${s.iconPlus}`
                }
              />
            </div>
          </div>
          <div className={this.state.emergencyContactInfoExpanded ? 'form form--default' : `${s.hidden}`}>
            <div className={`${s.inputWrapper}`}>
              <Headline as="h3" className={s.subHeader} aria-label="Primary Emergency Contact">
                Primary Emergency Contact
              </Headline>
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="emergency_contact_first_name"
                id="emergency_contact_first_name"
                value={this.state.emergency_contact_first_name}
                onChange={this.onInputChange}
                label="First Name"
                disabled={readonly.includes('emergency_contact_first_name') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="emergency_contact_last_name"
                id="emergency_contact_last_name"
                value={this.state.emergency_contact_last_name}
                onChange={this.onInputChange}
                label="Last Name"
                disabled={readonly.includes('emergency_contact_last_name') || disableAll}
              />
            </div>
            <div className={`emergencyRelationship ${s.inputWrapper}`}>
              <label className={`${s.emergencyRelationshipLabel}`}>Emergency Contact Relationship</label>
              <Dropdown
                name="emergency_contact_relationship"
                id="emergency_contact_relationship"
                placeholder="Relationship"
                value={this.state.emergency_contact_relationship}
                onChange={this.onSelectChange}
                options={[
                  { value: null, label: '' },
                  { value: 'Mother', label: 'Mother' },
                  { value: 'Father', label: 'Father' },
                  { value: 'Parent', label: 'Parent' },
                  { value: 'Grandparent', label: 'Grandparent' },
                  { value: 'Mentor', label: 'Mentor' },
                  { value: 'Sister', label: 'Sister' },
                  { value: 'Brother', label: 'Brother' },
                  { value: 'Teacher', label: 'Teacher' },
                  {
                    value: 'Other Family Member',
                    label: 'Other Family Member',
                  },
                  { value: 'Other', label: 'Other' },
                ]}
                disabled={readonly.includes('emergency_contact_relationship') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="emergency_contact_email"
                id="emergency_contact_email"
                value={this.state.emergency_contact_email}
                onChange={this.onInputChange}
                label="Email"
                disabled={readonly.includes('emergency_contact_email') || disableAll}
                onBlur={this.validateEmail.bind(this)}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              {readonly.includes('emergency_contact_phone_number') ? (
                <TextInput
                  name="emergency_contact_phone_number"
                  id="emergency_contact_phone_number"
                  value={this.state.emergency_contact_phone_number}
                  label="Phone Number"
                  onChange={this.onInputChange}
                  disabled={true}
                />
              ) : (
                <TelInput
                  id="emergency_contact_country_code"
                  countryCodeValue={this.state.emergency_contact_country_code || '+1'}
                  phoneValue={this.state.emergency_contact_phone_number}
                  countryCodeField={'emergency_contact_country_code'}
                  phoneField={'emergency_contact_phone_number'}
                  label="Phone Number"
                  value={`${this.state.emergency_contact_country_code || '+1'}${
                    this.state.emergency_contact_phone_number
                  }`}
                  onChange={this.onInputChange}
                  handleChange={this.handleChange}
                  disabled={disableAll}
                />
              )}
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="emergency_contact_address_line_1"
                id="emergency_contact_address_line_1"
                value={this.state.emergency_contact_address_line_1}
                onChange={this.onInputChange}
                label="Address"
                disabled={readonly.includes('emergency_contact_address_line_1') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="emergency_contact_address_line_2"
                id="emergency_contact_address_line_2"
                value={this.state.emergency_contact_address_line_2}
                onChange={this.onInputChange}
                label="Address Line 2"
                disabled={readonly.includes('emergency_contact_address_line_2') || disableAll}
              />
            </div>
            <div className={`emergencyCountry ${s.inputWrapper}`}>
              <label className={`${s.countryLabel}`}>Emergency Contact Country</label>
              <Dropdown
                name="emergency_contact_address_country"
                id="emergency_contact_address_country"
                placeholder="Country"
                value={this.state.emergency_contact_address_country}
                onChange={this.onSelectChange}
                options={[{ ...nullCountry }, ...countries]}
                disabled={readonly.includes('emergency_contact_address_country') || disableAll}
              />
            </div>
            <div className={`travelerEmergencyState ${s.inputWrapper}`}>
              <div className={this.state.emergency_contact_address_country === 'US' ? '' : `${s.hidden}`}>
                <label className={`${s.travelerEmergencyState}`}>Emergency Contact State</label>
                <Dropdown
                  name={this.state.emergency_contact_address_country === 'US' ? 'emergency_contact_address_state' : ''}
                  id="emergency_contact_address_state_us"
                  options={States}
                  placeholder="State/Province/Territory"
                  onChange={this.onSelectChange}
                  value={this.state.emergency_contact_address_state}
                  disabled={readonly.includes('emergency_contact_address_state') || disableAll}
                />
              </div>
            </div>
            <div className={`travelerEmergencyState ${s.inputWrapper}`}>
              <div className={this.state.emergency_contact_address_country === 'CA' ? '' : `${s.hidden}`}>
                <Dropdown
                  name={this.state.emergency_contact_address_country === 'CA' ? 'emergency_contact_address_state' : ''}
                  id={'emergency_contact_address_state_ca'}
                  options={Provinces}
                  placeholder="State/Province/Territory"
                  onChange={this.onSelectChange}
                  value={this.state.emergency_contact_address_state}
                  disabled={readonly.includes('emergency_contact_address_state') || disableAll}
                />
              </div>
            </div>
            {this.state.emergency_contact_address_country !== 'US' &&
              this.state.emergency_contact_address_country !== 'CA' && (
                <div className={`travelerEmergencyState ${s.inputWrapper}`}>
                  <TextInput
                    name={
                      this.state.emergency_contact_address_country !== 'US' &&
                      this.state.emergency_contact_address_country !== 'CA'
                        ? 'emergency_contact_address_state'
                        : ''
                    }
                    id="emergency_contact_address_state_nonusca"
                    value={this.state.emergency_contact_address_state}
                    onChange={this.onInputChange}
                    label="State/Province/Territory"
                    disabled={readonly.includes('emergency_contact_address_state') || disableAll}
                  />
                </div>
              )}
            <div className={`travelerEmergencyState ${s.inputWrapper}`}>
              <TextInput
                name="emergency_contact_address_city"
                id="emergency_contact_address_city"
                value={this.state.emergency_contact_address_city}
                onChange={this.onInputChange}
                label="City"
                disabled={readonly.includes('emergency_contact_address_city') || disableAll}
              />
            </div>
            <div className={`travelerEmergencyState ${s.inputWrapper}`}>
              <TextInput
                name="emergency_contact_address_zip_code"
                id="emergency_contact_address_zip_code"
                value={this.state.emergency_contact_address_zip_code}
                onChange={this.onInputChange}
                label="Zip Code"
                // options={
                //   this.state.permanent_address_country === 'US'
                //     ? {
                //         numericOnly: true,
                //         delimiterLazyShow: true,
                //         blocks: [5, 5],
                //         delimiters: ['-'],
                //       }
                //     : { numericOnly: true }
                // }
                disabled={readonly.includes('emergency_contact_address_zip_code') || disableAll}
              />
            </div>
            <div className={`travelerEmergencyState ${s.inputWrapper}`}>
              <Headline as="h3" className={s.subHeader} aria-label="Secondary Emergency Contact">
                Secondary Emergency Contact
              </Headline>
            </div>
            <div className={`travelerEmergencyState ${s.inputWrapper}`}>
              <TextInput
                name="secondary_emergency_contact_first_name"
                id="secondary_emergency_contact_first_name"
                value={this.state.secondary_emergency_contact_first_name}
                onChange={this.onInputChange}
                label="First Name"
                disabled={readonly.includes('secondary_emergency_contact_first_name') || disableAll}
              />
            </div>
            <div className={`travelerEmergencyState ${s.inputWrapper}`}>
              <TextInput
                name="secondary_emergency_contact_last_name"
                id="secondary_emergency_contact_last_name"
                value={this.state.secondary_emergency_contact_last_name}
                onChange={this.onInputChange}
                label="Last Name"
                disabled={readonly.includes('secondary_emergency_contact_last_name') || disableAll}
              />
            </div>
            <div className={`secondEmergencyRelationship ${s.inputWrapper}`}>
              <label className={`${s.secondEmergencyRelationship}`}>Second Emergency Contact Relationship</label>
              <Dropdown
                name="secondary_emergency_contact_relationship"
                id="secondary_emergency_contact_relationship"
                placeholder="Relationship"
                value={this.state.secondary_emergency_contact_relationship}
                onChange={this.onSelectChange}
                options={[
                  { value: null, label: '' },
                  { value: 'Mother', label: 'Mother' },
                  { value: 'Father', label: 'Father' },
                  { value: 'Parent', label: 'Parent' },
                  { value: 'Grandparent', label: 'Grandparent' },
                  { value: 'Mentor', label: 'Mentor' },
                  { value: 'Sister', label: 'Sister' },
                  { value: 'Brother', label: 'Brother' },
                  { value: 'Teacher', label: 'Teacher' },
                  {
                    value: 'Other Family Member',
                    label: 'Other Family Member',
                  },
                  { value: 'Other', label: 'Other' },
                ]}
                disabled={readonly.includes('secondary_emergency_contact_relationship') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="secondary_emergency_contact_email"
                id="secondary_emergency_contact_email"
                value={this.state.secondary_emergency_contact_email}
                onChange={this.onInputChange}
                label="Email"
                disabled={readonly.includes('secondary_emergency_contact_email') || disableAll}
                onBlur={this.validateEmail.bind(this)}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              {readonly.includes('secondary_emergency_contact_phone_number') ? (
                <TextInput
                  name="secondary_emergency_contact_phone_number"
                  id="secondary_emergency_contact_phone_number"
                  value={this.state.secondary_emergency_contact_phone_number}
                  label="Phone Number"
                  onChange={this.onInputChange}
                  disabled={true}
                />
              ) : (
                <TelInput
                  countryCodeValue={this.state.secondary_emergency_contact_country_code || '+1'}
                  phoneValue={this.state.secondary_emergency_contact_phone_number}
                  countryCodeField={'secondary_emergency_contact_country_code'}
                  phoneField={'secondary_emergency_contact_phone_number'}
                  name="secondary_emergency_contact_phone_number"
                  id="secondary_emergency_contact_phone_number"
                  label="Phone Number"
                  value={`${this.state.secondary_emergency_contact_country_code || '+1'}${
                    this.state.secondary_emergency_contact_phone_number
                  }`}
                  onChange={this.onInputChange}
                  handleChange={this.handleChange}
                  disabled={readonly.includes('secondary_emergency_contact_phone_number') || disableAll}
                />
              )}
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="secondary_emergency_contact_address_line_1"
                id="secondary_emergency_contact_address_line_1"
                value={this.state.secondary_emergency_contact_address_line_1}
                onChange={this.onInputChange}
                label="Address"
                disabled={readonly.includes('secondary_emergency_contact_address_line_1') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="secondary_emergency_contact_address_line_2"
                id="secondary_emergency_contact_address_line_2"
                value={this.state.secondary_emergency_contact_address_line_2}
                onChange={this.onInputChange}
                label="Address Line 2"
                disabled={readonly.includes('secondary_emergency_contact_address_line_2') || disableAll}
              />
            </div>
            <div className={`secondEmergencyCountry ${s.inputWrapper}`}>
              <label className={`${s.secondEmergencyCountry}`}>Second Emergency Contact Country</label>
              <Dropdown
                name="secondary_emergency_contact_address_country"
                index="secondary_emergency_contact_address_country"
                placeholder="Country"
                value={this.state.secondary_emergency_contact_address_country}
                onChange={this.onSelectChange}
                options={[{ ...nullCountry }, ...countries]}
                disabled={readonly.includes('secondary_emergency_contact_address_country') || disableAll}
              />
            </div>
            {this.state.secondary_emergency_contact_address_country === 'US' && (
              <div className={`secondEmergencyState ${s.inputWrapper}`}>
                <Dropdown
                  name={
                    this.state.secondary_emergency_contact_address_country === 'US'
                      ? 'secondary_emergency_contact_address_state'
                      : ''
                  }
                  id="secondary_emergency_contact_address_state_us"
                  options={States}
                  placeholder="State/Province/Territory"
                  onChange={this.onSelectChange}
                  value={this.state.secondary_emergency_contact_address_state}
                  disabled={readonly.includes('secondary_emergency_contact_address_state') || disableAll}
                />
              </div>
            )}
            {this.state.secondary_emergency_contact_address_country === 'CA' && (
              <div className={`${s.inputWrapper}`}>
                <Dropdown
                  name={
                    this.state.secondary_emergency_contact_address_country === 'CA'
                      ? 'secondary_emergency_contact_address_state'
                      : ''
                  }
                  // value={this.state.secondary_emergency_contact_address_country}
                  id="secondary_emergency_contact_address_state_ca"
                  options={Provinces}
                  placeholder="State/Province/Territory"
                  onChange={this.onSelectChange}
                  value={this.state.secondary_emergency_contact_address_state}
                  disabled={readonly.includes('secondary_emergency_contact_address_state') || disableAll}
                />
              </div>
            )}
            {this.state.secondary_emergency_contact_address_country !== 'US' &&
              this.state.secondary_emergency_contact_address_country !== 'CA' && (
                <div className={`${s.inputWrapper}`}>
                  <TextInput
                    name={
                      this.state.secondary_emergency_contact_address_country !== 'US' &&
                      this.state.secondary_emergency_contact_address_country !== 'CA'
                        ? 'secondary_emergency_contact_address_state'
                        : ''
                    }
                    id="secondary_emergency_contact_address_state"
                    value={this.state.secondary_emergency_contact_address_state}
                    onChange={this.onInputChange}
                    label="State/Province/Territory"
                    disabled={readonly.includes('secondary_emergency_contact_address_state') || disableAll}
                  />
                </div>
              )}
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="secondary_emergency_contact_address_city"
                id="secondary_emergency_contact_address_city"
                value={this.state.secondary_emergency_contact_address_city}
                onChange={this.onInputChange}
                label="City"
                disabled={readonly.includes('secondary_emergency_contact_address_city') || disableAll}
              />
            </div>
            <div className={`${s.inputWrapper}`}>
              <TextInput
                name="secondary_emergency_contact_address_zip_code"
                id="secondary_emergency_contact_address_zip_code"
                value={this.state.secondary_emergency_contact_address_zip_code}
                onChange={this.onInputChange}
                label="Zip Code"
                // options={
                //   this.state.permanent_address_country === 'US'
                //     ? {
                //         numericOnly: true,
                //         delimiterLazyShow: true,
                //         blocks: [5, 5],
                //         delimiters: ['-'],
                //       }
                //     : { numericOnly: true }
                // }
                disabled={readonly.includes('secondary_emergency_contact_address_zip_code') || disableAll}
              />
            </div>
          </div>
          <div className={s.accordion} data-block-type="passportVisaExpanded" onClick={this.openBlock}>
            <div className={s.sectionHeader}>
              <Headline
                as="h2"
                font="avenirMedium"
                aria-label="Passport & Visa Status"
                data-block-type="passportVisaExpanded"
              >
                Passport & Visa Status
              </Headline>
            </div>
            <div className={s.iconWrapper}>
              <div
                data-block-type="passportVisaExpanded"
                className={this.state.passportVisaExpanded ? `${s.icon} ${s.iconMinus}` : `${s.icon} ${s.iconPlus}`}
              />
            </div>
          </div>
          <div className={this.state.passportVisaExpanded ? 'form form--default' : `${s.hidden}`}>
            <div className={s.inputWrapper}>
              <TextInput
                name="place_of_birth"
                id="place_of_birth"
                value={this.state.place_of_birth}
                onChange={this.onInputChange}
                label="Place of Birth"
                disabled={readonly.includes('place_of_birth') || disableAll}
              />
            </div>
            <div className={s.inputWrapper}>
              <div className={`countries_of_citizenship ${s.multiSelect}`}>
                <label
                  className={`${s.multiLabel} ${s.dobLabel} ${
                    this.state.countries_of_citizenship != '' ? `${s.multiFieldAdded}` : ''
                  }`}
                >
                  Country/Countries of Citizenship
                </label>
                <Select
                  isMulti={true}
                  name="countries_of_citizenship"
                  id="countries_of_citizenship"
                  onChange={this.setMultipleValues}
                  options={countries}
                  value={countries.filter(country => {
                    return this.state.countries_of_citizenship.includes(country.value);
                  })}
                  styles={customStyles}
                  placeholder={null}
                  isDisabled={readonly.includes('countries_of_citizenship') || disableAll}
                  className="profile-select"
                />
              </div>
            </div>
            <div className={`passportStatus ${s.inputWrapper}`}>
              <label>Passport Status</label>
              <Dropdown
                name="passport_status"
                id="passport_status"
                value={this.state.passport_status === 'not_provided' ? '' : this.state.passport_status}
                onChange={this.onSelectChange}
                options={[
                  { value: 'current', label: 'Current' },
                  { value: 'expired', label: 'Expired' },
                  { value: 'no_passport', label: "I don't have a passport" },
                  { value: 'unknown', label: "I don't know" },
                ]}
                placeholder="Passport Status"
                disabled={readonly.includes('passport_status') || disableAll}
              />
            </div>
            <div className={s.inputWrapper}>
              <span className={s.password_show} onClick={this.showHide}>
                {this.state.inputType === 'text' ? (
                  <img className={s.iconSize} src={eye} />
                ) : (
                  <img className={s.iconSize} src={hiddenEye} />
                )}
              </span>
              <TextInput
                name="passport_number"
                value={this.state.passport_number}
                onChange={this.onInputChange}
                label="Passport Number"
                type={this.state.inputType}
                disabled={readonly.includes('passport_number') || disableAll}
                onBlur={this.showHide}
              />
            </div>
            <div className={`ped ${s.inputWrapper}`}>
              <span className={s.password_show} onClick={this.togglePassportExpirationDate}>
                {this.state.pedType ? (
                  <img className={s.iconSize} src={hiddenEye} />
                ) : (
                  <img className={s.iconSize} src={eye} />
                )}
              </span>
              <label className={`${s.dobLabel}`}>Passport Expiration Date</label>
              <div className={`${hidePassportExpiration}`}>
                {hidePassportExpiration ? (
                  <input
                    type="password"
                    value={'************'}
                    readOnly
                    style={{ marginBottom: 10 }}
                    onClick={() => this.setState({ pedType: false })}
                  />
                ) : (
                  <SingleDatePicker
                    id="passport_expiration_date"
                    // name="ped"
                    displayFormat="MMM DD, YYYY"
                    dayAriaLabelFormat="MMM DD, YYYY"
                    readOnly
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                    renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                      <div className={s.dateSelectContainer}>
                        <div>
                          <select
                            value={month.month()}
                            onChange={e => {
                              onMonthSelect(month, e.target.value);
                            }}
                          >
                            {moment.months().map((label, value) => (
                              <option value={value}>{label}</option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <select
                            value={month.year()}
                            onChange={e => {
                              onYearSelect(month, e.target.value);
                            }}
                          >
                            {passportExpirationYearRange().map(range => (
                              <option value={range}>{range}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    date={this.state.passport_expiration_date && moment(this.state.passport_expiration_date)}
                    onDateChange={date => {
                      this.setState({ passport_expiration_date: date });
                    }}
                    focused={this.state.pedFocused}
                    onFocusChange={({ focused }) => {
                      this.setState({ pedFocused: focused });
                      focused ? this.setState({ pedType: false }) : null;
                    }}
                    disabled={readonly.includes('passport_expiration_date') || disableAll}
                  />
                )}
              </div>
            </div>{' '}
            {/* <div className={`ped ${s.inputWrapper}`}>
              <label className={`${s.pedLabel}`}>Passport Expiration Date</label>
              <DateDropdown
                name="pedMonth"
                placeholder="Month"
                value={this.state.pedMonth}
                onChange={this.onSelectChange}
                options={monthsOptions}
                disabled={readonly.includes('passport_expiration_date') || disableAll}
                className={`dateDropdown`}
              />
              <DateDropdown
                name="pedDay"
                value={this.state.pedDay}
                onChange={this.onSelectChange}
                placeholder="Day"
                options={daysOptions}
                disabled={readonly.includes('passport_expiration_date') || disableAll}
                className={`dateDropdown`}
              />
              <DateDropdown
                name="pedYear"
                value={this.state.pedYear}
                onChange={this.onSelectChange}
                placeholder="Year"
                options={expiryYearsOptions}
                disabled={readonly.includes('passport_expiration_date') || disableAll}
                className={`dateDropdown`}
              />
            </div> */}
            <div className={s.inputWrapper}>
              <TextInput
                name="passport_issuing_agency"
                value={this.state.passport_issuing_agency}
                onChange={this.onInputChange}
                label="Passport Issuing Agency"
                disabled={readonly.includes('passport_issuing_agency') || disableAll}
              />
            </div>
            <div className={s.inputWrapper}>
              <TextInput
                name="visa_description"
                value={this.state.visa_description}
                onChange={this.onInputChange}
                label="Visa Status"
                disabled={readonly.includes('visa_description') || disableAll}
              />
            </div>
          </div>
          {customFields.length > 0 && (
            <React.Fragment>
              <div className={s.accordion} data-block-type="customFieldsExpanded" onClick={this.openBlock}>
                <div className={s.sectionHeader}>
                  <Headline
                    as="h2"
                    font="avenirMedium"
                    aria-label="Custom Fields"
                    data-block-type="customFieldsExpanded"
                  >
                    {this.props.role === 'Admin' ? `Custom Fields` : 'Additional Information'}
                  </Headline>
                </div>
                <div className={s.iconWrapper}>
                  <div
                    data-block-type="customFieldsExpanded"
                    className={this.state.customFieldsExpanded ? `${s.icon} ${s.iconMinus}` : `${s.icon} ${s.iconPlus}`}
                  />
                </div>
              </div>
              <div className={this.state.customFieldsExpanded ? 'form form--default' : `${s.hidden}`}>
                <div className={s.inputWrapper}>{customFields}</div>
              </div>
            </React.Fragment>
          )}
        </form>
      );
    }
  }
}

const mapStateToProps = state => ({
  allGenderIdentities: state.profile.allGenderIdentities,
  profile: state.profile.profile,
  permissions: state.profile.permissions,
  currentUser: state.profile.currentUser,
  customAliases: state.profile.customAliases,
  clientFeatureList: state.profile.clientFeatureList,
});

export default compose(
  withCookies,
  withStyles(reactDatesStyles, reactTelSelectStyles, reactSelectStyles, s),
  connect(mapStateToProps, { updateProfileData }),
)(Profile);
